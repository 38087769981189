import { Component } from '@angular/core';
import {fadeAnimation} from "src/app/shared/animations/fade.animation";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
  animations: [fadeAnimation]
})
export class AppComponent {

  page_load: boolean = true;

  constructor() {
    if (window.innerWidth >= 992) {
      this.page_load = true;
    } else {
      this.page_load = false;
    }
  }
}
