import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AppHttpService } from 'src/app/shared/services/app-http.service';
import * as decode from 'jsonwebtoken';
let baseUrl = environment.baseUrl;
@Injectable({
    providedIn: "root"
})
export class AuthService {
    constructor(private http: AppHttpService) {
    }
    loginUser(user) {
        return this.http.post('/login', user);
    }
    sendOtp(data) {
        return this.http.post('/sendotp', data);
    }
    otpVerify(data) {
        return this.http.post('/vendorverifypassword', data);
    }
    verifyPassword(data, token) {
        return this.http.post('/user/vendorresetpassword', data, null, { 'Authorization' : 'Bearer ' + token  });
    }
    
    getFullToken() {
        return localStorage.getItem("api_token");
    }
    getToken() {
        return !!localStorage.getItem("api_token");
    }    
}
